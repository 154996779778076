// @ts-nocheck

import { getFeatures, TizenPerformanceFeaturesMap } from '@package/sdk/src/smarttv';
import { getTizenVersion } from '@package/sdk/src/smarttv/utils/platform';

import { isUndefinedOrNull } from '../../../core/std/types';
import logger from '../logger/logger';
import { AbstractManufacturerService } from './abstract-manufacturer-service';

export namespace TizenOS {
  export interface ApplicationInstance {
    exit(): void;
    appInfo: {
      id: string;
    };
  }

  export interface TVInputDeviceService {
    registerKey(key: string): void;
  }

  export interface ApplicationService {
    getCurrentApplication(): ApplicationInstance;
  }

  export interface NetworkService {
    isConnectedToGateway(): boolean;
  }

  export interface Instance {
    tvinputdevice: TVInputDeviceService;
    application: ApplicationService;
    network: NetworkService;
  }
}

declare global {
  const tizen: TizenOS.Instance;

  interface Window {
    tizen: TizenOS.Instance;
  }
}

const TIZEN_CHROME_VERSION = {
  '8': 108,
  '7': 94,
  '6.5': 85,
  '6': 76,
  '5.5': 69,
  '5': 63,
  '4': 56,
  '3': 47,
};

const VENDOR_NAME = 'Samsung';
const DEFAULT_DEVICE_NAME = `${VENDOR_NAME}`;
const DEFAULT_APP_ID = 'samsung.unknown.appId';

const registerTizenKeys = [
  'MediaPlay',
  'MediaPause',
  'MediaStop',
  'MediaRewind',
  'MediaFastForward',
  'ChannelUp',
  'ChannelDown',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

const getSafeHumanReadableModelName = () => {
  if (isUndefinedOrNull(window.webapis)) {
    return DEFAULT_DEVICE_NAME;
  }

  try {
    const realModelName = window.webapis.productinfo.getRealModel();
    const realModelCode = window.webapis.productinfo.getModelCode();

    if (realModelName) {
      return `${VENDOR_NAME} ${realModelName} ${realModelCode}`;
    }
  } catch (error) {
    logger.error(error);

    return DEFAULT_DEVICE_NAME;
  }

  return DEFAULT_DEVICE_NAME;
};

const getSafeApplicationId = () => {
  try {
    const id = window.tizen.application.getCurrentApplication().appInfo.id;

    if (id) {
      return id;
    }

    return DEFAULT_APP_ID;
  } catch (error) {
    return DEFAULT_APP_ID;
  }
};

export class TizenService extends AbstractManufacturerService {
  private _map = new TizenPerformanceFeaturesMap();

  constructor() {
    super();
  }

  public isWeakDevice(): boolean {
    return false;
  }

  public getAvailableFeatures() {
    const tizenVersion = getTizenVersion();
    const chromeVersion = TIZEN_CHROME_VERSION[tizenVersion.toString()];

    return getFeatures(this._map, chromeVersion);
  }

  public getHumanReadableDeviceName(): string {
    return getSafeHumanReadableModelName();
  }

  public getManufacturerName(): string {
    return VENDOR_NAME;
  }

  public getDeviceInfo(): unknown {
    return {
      appId: getSafeApplicationId(),
      modelName: this.getHumanReadableDeviceName(),
    };
  }

  public init() {
    try {
      for (const key of registerTizenKeys) {
        window.tizen.tvinputdevice.registerKey(key);
      }
    } catch (error) {
      logger.error('TizenService#init', JSON.stringify(error.message));
    }

    return Promise.resolve(undefined);
  }

  public exit() {
    window.tizen.application.getCurrentApplication().exit();
  }
}
