import { ApplicationError } from './application-error';

export class UnexpectedComponentStateError extends ApplicationError {
  public readonly name = 'UnexpectedComponentStateError';

  constructor(private readonly property: string) {
    super(`Expect ${property} to be defined`);
  }

  public toJSON(): Record<string, any> {
    const { property } = this;

    return {
      property,
    };
  }
}
