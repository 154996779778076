import { scrollTo } from 'scroll-polyfill';

import { isDefined } from '../../core/std/types';

export function scrollToElement(element: HTMLElement, options: ScrollToOptions) {
  if (isDefined(element.scrollTo)) {
    return element.scrollTo(options);
  }

  return scrollTo(element, options);
}
