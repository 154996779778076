import { ProcessingMapper } from '../base/processing-mapper';
import { ApiQuizGenreItem } from './quiz-genre-item-types';
import { QuizGenreItem } from './types/quiz-genre-item';

export class QuizGenreItemMapper {
  static mapMany(items: ApiQuizGenreItem[]): QuizGenreItem[] {
    return ProcessingMapper.process(items.map(QuizGenreItemMapper.map));
  }

  static map({ genre_id, ...rest }: ApiQuizGenreItem): QuizGenreItem {
    return ProcessingMapper.process({
      ...rest,
      genreId: genre_id,
    });
  }
}
