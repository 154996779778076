import { AbstractManufacturerService } from './abstract-manufacturer-service';
import { getFeatures, PerformanceFeatureMap } from './performance-features';

export class DesktopService extends AbstractManufacturerService {
  private _map = new PerformanceFeatureMap();

  constructor() {
    super();
  }

  public getAvailableFeatures() {
    return getFeatures(this._map);
  }

  public getHumanReadableDeviceName(): string {
    return 'Desktop APP';
  }

  public getManufacturerName(): string {
    return 'Desktop';
  }

  public getDeviceInfo(): unknown {
    return undefined;
  }

  public init() {
    return Promise.resolve(undefined);
  }

  public isWeakDevice(): boolean {
    return false;
  }

  public exit() {}
}
