import { SubscriptionState } from '../../api/subscriptions/types/subscription-state';
import { isDefined } from '../../core/std/types';
import { tPlural, translate } from '../services/localization/translate';
import { useContentAvailability } from './use-availability';

export interface IOfferInformation {
  price?: number;
  trialDuration?: number;
  trialAvailable?: boolean;
}

export enum OfferTextType {
  Try = 'try',
  WatchTrial = 'watchByTrial',
  WatchPrice = 'watchByPrice',
  ContinueWatchPrice = 'continueWatchByPrice',
  Partner = 'partner',
}

export type EpisodeData = {
  episode: number;
  season: number;
};

export interface SubscribePromoTexts {
  subscribeButtonText?: string;
  subscribeButtonSubtext?: string;
}

export const DEFAULT_TRIAL_PRICE = 1;
export const DEFAULT_TRIAL_DURATION = 14;

export const getOfferText = (
  offer: IOfferInformation,
  offerTextType: OfferTextType,
  subscribePromoTexts: SubscribePromoTexts = {},
): string | string[] => {
  const price = offer?.price as number;
  const duration = offer?.trialDuration || DEFAULT_TRIAL_DURATION;

  const { subscribeButtonText } = subscribePromoTexts;

  if (subscribeButtonText) {
    return subscribeButtonText;
  }

  switch (offerTextType) {
    case OfferTextType.Try:
      return tPlural('offer.common.tryForDaysAndPrice', duration, { count: duration, price: DEFAULT_TRIAL_PRICE });
    case OfferTextType.WatchTrial:
      return tPlural('offer.common.watchDaysByTrial', duration, { count: duration, price: DEFAULT_TRIAL_PRICE });
    case OfferTextType.WatchPrice:
      return translate('offer.common.watchByPrice', { price });
    case OfferTextType.ContinueWatchPrice:
      return translate('offer.common.continueWatchByPrice', { price });
    case OfferTextType.Partner:
      return translate('offer.common.continueSubscription');
    default:
      return translate('button.watch');
  }
};

export type ContentAccessKind = 'all_users' | 'register' | 'subscription';

export type ContentType = 'movie' | 'serial' | 'season' | 'episode' | 'tv' | 'kinom';
type AvailabilityType = 'available' | 'unavailable' | 'available_soon' | 'unavailable_soon' | 'timeshift_available';

export interface ITVProgram {
  id: string;
  cast: string[];
  genre: string[];
  director: string[];
  title?: string;
  slug: string;
  sequence_number: number;
  live?: boolean;
  subtitled: boolean;
  premiere?: boolean;
  season_number?: number;
  episode_number?: number;
  episode_title?: string;
  episode_synopsis?: string;
  age_limit: number;
  description?: string;
  background?: string;
  start_time: string;
  duration: number;
  content_id?: string;
  content_type?: ContentType;
}

export interface IWatchingItem {
  id: string;
  offset: number;
}

export interface IContentMediaTransformed {
  metadata: Record<string, number>;
  id: string;
  ageLimit: number;
  contentId: string;
  accessKind: ContentAccessKind;
  contentSlug: string;
  contentTitle: string;
  contentYear: string;
  contentType: ContentType;
  contentPoster: string;
  number: number;
  episodeNumber: number;
  seasonNumber: number;
  description?: string;
  startOffset: number;
  slug: string;
  smokingAdText?: boolean;
  title: string;
  logo: string;
  logoWhite: string;
  logoBlack: string;
  licenseDateStart: string;
  licenseDateEnd: string;
  licenseLockedInfo: string;
  preview: string;
  poster: string;
  duration: number;
  inUserCollection: boolean;
  canCreateUGC: boolean;
  canCreateUgc: boolean;
  currentProgram: ITVProgram;
  watchingItem: IWatchingItem;

  seasons: IMediaSeason[];
  availability: AvailabilityType;
  hls: string;
  year: number;
}

export type IContentMedia = IContentMediaTransformed;

export interface IMediaSeason {
  id: string;
  title: string;
  number: number;
  description: string;
  slug: string;
  episodes: Partial<IContentMedia>[];
  contentMomentsList: Partial<IContentMedia>[];
}

interface IGetPlayButtonTextOptions {
  subscribePromoTexts?: SubscribePromoTexts;
  accessKind?: ContentAccessKind;
  isActiveSubscription?: boolean;
  isPartnerSubscription?: boolean;
  hasTrialOffer?: boolean;
  isVOD?: boolean;
  isAuth?: boolean;
  isLive?: boolean;
  canContinueWatch?: boolean;
  offer?: any;
  subscription?: any;
  content?: IContentMedia;
  episodeData?: EpisodeData;
  isWatchButtonWithContentTypeText?: boolean;
  isForceSimpleWatchButtonText?: boolean;
}

export const getPlayButtonText = (options: IGetPlayButtonTextOptions): string | string[] => {
  const {
    isLive,
    isAuth,
    isVOD,
    canContinueWatch,
    isActiveSubscription,
    subscription,
    episodeData,
    hasTrialOffer,
    offer,
    accessKind,
    subscribePromoTexts = {},
    isWatchButtonWithContentTypeText,
    isForceSimpleWatchButtonText,
  } = options;

  if (isForceSimpleWatchButtonText) {
    return translate('button.watch');
  }

  // Если подписка активная
  if (isActiveSubscription) {
    // Если это плеер сериала или фильма
    if (isVOD && isWatchButtonWithContentTypeText) {
      // если есть watching item
      if (canContinueWatch) {
        return translate('button.continueWatch');
      }
      // если это сериал
      if (episodeData) {
        return translate('button.watchEpisode', episodeData as EpisodeData);
      }
      // если фильм
      return translate('button.watchMovie');
    }

    // Если это плеер трансляции или тв-канала
    if (isLive && isDefined(accessKind)) {
      return translate('button.watchLive');
    }

    // Для остального просто "Смотреть"
    return translate('button.watch');
  }

  if (isAuth && !isActiveSubscription) {
    if (isDefined(accessKind) && (accessKind === 'register' || accessKind === 'all_users')) {
      if (isLive) {
        return translate('button.watchLive');
      }

      return translate('button.watch');
    }
  }

  if (!isAuth) {
    if (isDefined(accessKind) && accessKind === 'register') {
      return translate('button.registerToWatchChannel');
    }

    if (isDefined(accessKind) && accessKind === 'all_users') {
      return translate('button.watchLive');
    }

    return getOfferText(offer as IOfferInformation, OfferTextType.WatchTrial, subscribePromoTexts);
  }

  const textType =
    hasTrialOffer && subscription?.state !== SubscriptionState.Canceled
      ? OfferTextType.WatchTrial
      : OfferTextType.WatchPrice;

  return getOfferText(offer as IOfferInformation, textType, subscribePromoTexts);
};

export const getPlayButtonSubtitle = (options: IGetPlayButtonTextOptions): string | string[] => {
  const { isActiveSubscription, isPartnerSubscription, content, subscribePromoTexts = {} } = options;

  const { getAvailabilityMessage, isAvailable } = useContentAvailability();

  if (isPartnerSubscription && !isActiveSubscription) {
    return translate('offer.common.continueSubscription');
  }

  if (!isAvailable(content) || isActiveSubscription) {
    return getAvailabilityMessage(content, {
      isActiveSubscription,
    });
  }

  if (subscribePromoTexts.subscribeButtonSubtext) {
    return subscribePromoTexts.subscribeButtonSubtext;
  }

  return '';
};
