import { getChromeVersion } from '@package/sdk/src/smarttv';

type PerformanceFeatureItem = {
  fromVersion: number;
} | null;

export class PerformanceFeatureMap {
  public get features() {
    return {
      backdropFilter: this.backdropFilter(),
    };
  }

  backdropFilter(): PerformanceFeatureItem {
    return {
      fromVersion: 76,
    };
  }
}

export class TizenPerformanceFeaturesMap extends PerformanceFeatureMap {
  backdropFilter() {
    return null;
  }
}

export class WebosFeaturesMap extends PerformanceFeatureMap {
  backdropFilter(): PerformanceFeatureItem {
    return {
      fromVersion: 140,
    };
  }
}

export const getFeatures = (map: PerformanceFeatureMap, platformVersion?: number): string[] => {
  const version = platformVersion ?? getChromeVersion();
  if (!version) {
    return [];
  }

  const features: string[] = [];
  Object.entries(map.features).forEach(([key, value]) => {
    if (value && value.fromVersion <= version) {
      features.push(key);
    }
  });

  return features;
};
