import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { Channel, TVChannelAccessKind } from '@SDK/api/channels/types/channel';
import {
  RouterPage,
  storeToRefs,
  translate,
  useAuthActions,
  useAuthStore,
  useOfferActions,
  useSessionStore,
} from '@SDK/smarttv';

import { routerService } from '../../index';

export function useTvChannelActions() {
  const authStore = useAuthStore();
  const sessionStore = useSessionStore();

  const { isAuth, isActiveSubscription } = storeToRefs<any, any, any>(sessionStore);
  const { openOffersPage } = useOfferActions();
  const { openAuthPage } = useAuthActions();

  const isTvChannelPlaybackAvailable = (channel: Channel) => {
    const { accessKind } = channel;

    return isActiveSubscription.value || (isAuth.value && accessKind === 'register') || accessKind === 'all_users';
  };

  const openTvChannelPage = (channel: Channel) => {
    const { accessKind } = channel;

    const isPlaybackAvailable = isTvChannelPlaybackAvailable(channel);
    // Если юзер без авторизации, и канал доступен после реги.
    if (!isAuth.value && accessKind === 'register') {
      return openAuthPage();
    }

    // Если в целом нельзя посмотреть
    if (!isPlaybackAvailable) {
      authStore.setBackUrl(routerService.fullPath);

      return openOffersPage();
    }

    const channelRoute = { name: RouterPage.ChannelsPlayerPage, params: { id: channel.id } };

    // значит все ок, уводим на ТВ
    return routerService.push(channelRoute);
  };

  const getTvChannelStickerText = (accessKind: TVChannelAccessKind) => {
    if (isActiveSubscription.value) {
      return '';
    }

    // если доступен для всех юзеров
    if (accessKind === 'all_users') {
      return translate('pages.channels.watchNow');
    }

    // Доступен после реги
    if (isAuth.value && accessKind === 'register') {
      return translate('pages.channels.watchNow');
    }

    // Есть рега, но нет активной подписки
    if (isAuth.value && !isActiveSubscription.value && accessKind === 'subscription') {
      return translate('pages.channels.subscription');
    }

    // Если нет авторизации, и канал доступен после реги
    if (!isAuth.value && accessKind === 'register') {
      return translate('pages.channels.signUpAndWatch');
    }

    // Если без авторизации, и канал только по подписке
    if (!isAuth.value && accessKind === 'subscription') {
      return '';
    }

    throw new UnexpectedComponentStateError('accessKind');
  };

  return {
    getTvChannelStickerText,
    isTvChannelPlaybackAvailable,
    openTvChannelPage,
  };
}
