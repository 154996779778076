export enum AnalyticPageName {
  Kinom = 'kinom',
  Main = 'main',
  MyChannel = 'mychannel',
  Movie = 'movie',
  Series = 'series',
  TvChannel = 'tvchannel',
  Account = 'account',
  Collections = 'collections',
  Compilation = 'compilation',
  Tv = 'tv',
  Search = 'search',
  Item = 'item',
  Catalog = 'catalog',
  Unknown = 'other',
}
