import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';

interface LanguageChangeOptions {
  currentLang: string;
  page: AnalyticPageName;
}

interface LanguageSelectOptions {
  selectedLang: string;
  page: AnalyticPageName;
}

export function useAccountPageAnalytics() {
  const onClickLanguageChange = (options: LanguageChangeOptions) => {
    const { page, currentLang } = options;

    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickLanguageChange,
      page,
      values: [{ label: AnalyticPayloadLabelName.CurrentLang, value: currentLang }],
    });
  };

  const onClickLanguageSelect = (options: LanguageSelectOptions) => {
    const { page, selectedLang } = options;

    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickLanguageSelect,
      page,
      values: [{ label: AnalyticPayloadLabelName.SelectedLang, value: selectedLang }],
    });
  };

  const onGotoAccountPage = (page: AnalyticPageName) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoAccountPage,
      page,
    });
  };

  const onShowAccountPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowAccountPage,
      page: AnalyticPageName.Account,
    });
  };

  const onGotoDevicesPage = (page: AnalyticPageName) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoDevicesPage,
      page,
    });
  };

  const onShowDevicesPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowDevicesPage,
      page: AnalyticPageName.Account,
    });
  };

  const onGotoBonusesPage = (page: AnalyticPageName) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoBonusesPage,
      page,
    });
  };

  const onShowBonusesPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowBonusesPage,
      page: AnalyticPageName.Account,
    });
  };

  const onGotoProfilesPage = (page: AnalyticPageName) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoProfilesPage,
      page,
    });
  };

  const onShowProfilesPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowProfilesPage,
      page: AnalyticPageName.Account,
    });
  };

  const onGotoSubscribePage = (page: AnalyticPageName) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoSubscribePage,
      page,
    });
  };

  const onShowSubscribePage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribePage,
      page: AnalyticPageName.Account,
    });
  };

  const onClickAccountChangeEmail = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAccountChangeEmail,
      page: AnalyticPageName.Account,
    });
  };

  const onClickAccountChangeEmailConfirm = (newEmail: string) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAccountChangeEmailConfirm,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.NewValue,
          value: newEmail,
        },
      ],
    });
  };

  const onClickAccountChangeEmailCancel = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAccountChangeEmailCancel,
      page: AnalyticPageName.Account,
    });
  };

  const onClickAccountChangePhone = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAccountChangePhone,
      page: AnalyticPageName.Account,
    });
  };

  const onClickAccountChangePhoneConfirm = (newPhone: string) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAccountChangePhoneConfirm,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.NewValue,
          value: newPhone,
        },
      ],
    });
  };

  const onClickAccountChangePhoneCancel = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickAccountChangePhoneCancel,
      page: AnalyticPageName.Account,
    });
  };

  const onClickDevicesAddTv = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickDevicesAddTv,
      page: AnalyticPageName.Account,
    });
  };

  const onClickDevicesRemove = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickDevicesRemove,
      page: AnalyticPageName.Account,
    });
  };

  const onClickDevicesRemoveConfirm = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickDevicesRemoveConfirm,
      page: AnalyticPageName.Account,
    });
  };

  const onClickDevicesRemoveCancel = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickDevicesRemoveCancel,
      page: AnalyticPageName.Account,
    });
  };

  const onClickBonusesPromocode = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickBonusesPromocode,
      page: AnalyticPageName.Account,
    });
  };

  const onClickBonusesPromocodeApply = (promocode: string) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickBonusesPromocodeApply,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.PromoTitle,
          value: promocode,
        },
      ],
    });
  };

  const onClickBonusesReferral = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickBonusesReferral,
      page: AnalyticPageName.Account,
    });
  };

  const onClickBonusesReferralCopyLink = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickBonusesReferralCopyLink,
      page: AnalyticPageName.Account,
    });
  };

  const onClickBonusesReferralShare = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickBonusesReferralShare,
      page: AnalyticPageName.Account,
    });
  };

  const onClickExit = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickExit,
      page: AnalyticPageName.Account,
    });
  };

  const onClickExitConfirm = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickExitConfirm,
      page: AnalyticPageName.Account,
    });
  };

  const onClickExitCancel = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickExitCancel,
      page: AnalyticPageName.Account,
    });
  };

  const onAutoSubscribeCloudPaymentsDisplayed = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.AutoSubscribeCloudpaymentsDisplayed,
      page: AnalyticPageName.Account,
    });
  };

  return {
    onShowDevicesPage,
    onShowBonusesPage,
    onShowAccountPage,
    onShowProfilesPage,
    onShowSubscribePage,
    onClickLanguageChange,
    onClickLanguageSelect,
    onClickExit,
    onClickExitConfirm,
    onClickExitCancel,
    onClickAccountChangeEmail,
    onClickAccountChangeEmailConfirm,
    onClickAccountChangeEmailCancel,
    onClickAccountChangePhone,
    onClickAccountChangePhoneConfirm,
    onClickAccountChangePhoneCancel,
    onClickDevicesAddTv,
    onClickDevicesRemove,
    onClickDevicesRemoveConfirm,
    onClickDevicesRemoveCancel,
    onClickBonusesPromocode,
    onClickBonusesPromocodeApply,
    onClickBonusesReferral,
    onClickBonusesReferralCopyLink,
    onClickBonusesReferralShare,
    onGotoDevicesPage,
    onGotoBonusesPage,
    onGotoAccountPage,
    onGotoProfilesPage,
    onGotoSubscribePage,
    onAutoSubscribeCloudPaymentsDisplayed,
  };
}
