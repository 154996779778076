import { getFeatures, WebosFeaturesMap } from '@package/sdk/src/smarttv';

import { ApplicationError } from '../../../core';
import logger from '../logger/logger';
import { AbstractManufacturerService } from './abstract-manufacturer-service';

/** @see https://webostv.developer.lge.com/develop/references/tv-device-information */

export namespace WebOS {
  export enum SystemParameter {
    BoardType = 'boardType',
    ModelName = 'modelName',
    FirmwareVersion = 'firmwareVersion',
    UHD = 'UHD',
    SDKVersion = 'sdkVersion',
    Supports3D = '_3d',
  }

  export interface DeviceInfo {
    returnValue: boolean;
    errorCode?: string;
    errorText?: string;
    boardType?: string;
    modelName?: string;
    firmwareVersion?: string;
    sdkVersion?: string;
    UHD?: boolean;
    _3d?: boolean;
  }

  export interface SystemInfo {
    identifier: string;
    isKeyboardVisible: boolean;
    deviceInfo: string;
    timeZone: string;
    launchParams: string;
    launchReason: string;
    activityId: string;
    locale: string;
    localeRegion: string;
    tvSystemInfo: {
      countryGroup: string;
      tvSystemName: string;
    };
    highContrast: string;
    voiceControl: string;
    screenOrientation: string;
    currentOrientation: string;
    isActivated: boolean;
    phoneRegion: string;
    timeFormat: string;
    isMinimal: boolean;
  }

  export interface RequestParams {
    method: string;
    parameters: {
      keys: SystemParameter[];
    };
    onComplete: (response: DeviceInfo) => void;
    onFailure: (error: Error) => void;
  }

  export interface SystemInfoTransformed {
    appId?: string;
    deviceInfo?: string;
    modelName?: string;
    platformVersion?: string;
    sdkVersion?: string;
    firmwareVersion?: string;
    boardType?: string;
    screen: {
      screenOrientation?: string;
      screenWidth?: 1920;
      screenHeight?: 1080;
    };
    features: {
      UHD?: boolean;
      _3d?: boolean;
    };
  }

  export interface InternalLunaService {
    request: (service: string, params: WebOS.RequestParams) => void;
  }

  export interface KeyboardService {
    isShowing(): boolean;
  }

  export interface SystemInfoService {
    country: string;
  }

  export interface MagicRemoteEvent extends Event {
    detail: {
      type: 'Enter' | 'Leave';
    };
  }

  export interface KeyboardStateChangeEvent extends Event {
    detail: {
      visibility: boolean;
    };
  }

  export interface MagicRemoteCursorStateChangeEvent extends Event {
    detail: {
      visibility: boolean;
    };
  }
}

declare global {
  interface DocumentEventMap {
    webOSLaunch: Event;
    webOSRelaunch: Event;
    webOSLocaleChange: Event;
    webOSMouse: WebOS.MagicRemoteEvent;
    keyboardStateChange: WebOS.KeyboardStateChangeEvent;
    cursorStateChange: WebOS.MagicRemoteCursorStateChangeEvent;
  }
}

declare let webOS: {
  systemInfo: WebOS.SystemInfoService;
  service: WebOS.InternalLunaService;
  fetchAppId(): string;
  keyboard: WebOS.KeyboardService;
  platformBack(): void;
};

declare let webOSDev: {
  systemInfo: WebOS.SystemInfoService;
  service: WebOS.InternalLunaService;
  fetchAppId(): string;
  keyboard: WebOS.KeyboardService;
  platformBack(): void;
};

declare let webOSSystem: WebOS.SystemInfo;

const transformSystemWebOSInformation = (
  webOSSystem: WebOS.SystemInfo,
  deviceInfo: WebOS.DeviceInfo,
): WebOS.SystemInfoTransformed => {
  const info = JSON.parse(webOSSystem?.deviceInfo || 'null');

  return {
    appId: webOSSystem.identifier,
    modelName: deviceInfo.modelName,
    platformVersion: info.platformVersion,
    sdkVersion: deviceInfo.sdkVersion,
    firmwareVersion: deviceInfo.firmwareVersion,
    boardType: deviceInfo.boardType,
    deviceInfo: webOSSystem.deviceInfo,
    screen: {
      screenOrientation: webOSSystem.screenOrientation,
      screenWidth: info?.screenWidth,
      screenHeight: info?.screenHeight,
    },
    features: {
      UHD: deviceInfo.UHD,
      _3d: deviceInfo._3d,
    },
  };
};

export class WebOsSystemError extends ApplicationError {
  public readonly name = 'WebOsSystemError';

  constructor(message: string) {
    super(message);
  }

  public toJSON(): Record<string, any> {
    return {};
  }
}

const params = Object.values(WebOS.SystemParameter);
const getSystemWebOSInformation = (): Promise<WebOS.SystemInfoTransformed | undefined> => {
  return new Promise((resolve, reject) => {
    if (!('webos' in window)) {
      return undefined;
    }

    const onComplete = (response: WebOS.DeviceInfo) => {
      const isSucceeded = response.returnValue;

      if (isSucceeded) {
        const transformed = transformSystemWebOSInformation(webOSSystem, response);

        return resolve(transformed);
      }

      reject(new WebOsSystemError('Expect WebOSDeviceInfo.returnValue to be defined'));
    };

    try {
      webOS.service.request('luna://com.webos.service.tv.systemproperty', {
        method: 'getSystemInfo',
        parameters: { keys: params },
        onComplete,
        onFailure: (error) => reject(error),
      });
    } catch (error) {
      reject(error);
    }
  });
};

const VENDOR_NAME = 'LG';
const DEFAULT_DEVICE_NAME = `${VENDOR_NAME}`;

export class WebOsService extends AbstractManufacturerService {
  private systemInformation: WebOS.SystemInfoTransformed | undefined;

  constructor() {
    super();
  }

  public isWeakDevice(): boolean {
    return false;
  }

  public getAvailableFeatures() {
    return [];
  }

  public async init() {
    try {
      //  this.systemInformation = await getSystemWebOSInformation();
      // @ts-expect-error
      this.systemInformation = {};

      logger.info('system', this.systemInformation);
    } catch (error) {
      logger.error('init', error);
    }
  }

  public getHumanReadableDeviceName(): string {
    if (!this.systemInformation?.modelName) {
      return DEFAULT_DEVICE_NAME;
    }

    return `${VENDOR_NAME} ${this.systemInformation.modelName}`;
  }

  public getManufacturerName(): string {
    return VENDOR_NAME;
  }

  public getDeviceInfo() {
    return this.systemInformation;
  }

  public exit() {
    if (!('webOS' in window) || !('webOSDev' in window)) {
      return new WebOsSystemError('Expect window.webOS or window.webOSDev to be defined');
    }

    if ('webOS' in window) {
      return webOS.platformBack();
    } else {
      return webOSDev.platformBack();
    }
  }
}
