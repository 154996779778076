import { ProcessingMapper } from '../base/processing-mapper';
import { ApiEpisode } from './content-types/episode';
import { MediaMapper } from './media';
import { Episode } from './types/episode';

export class EpisodeMapper {
  static map(api: ApiEpisode): Episode {
    return ProcessingMapper.process({
      ...MediaMapper.map(api),
      number: api.number,
      tvChannelSlug: api.tv_channel_slug,
      timeshiftEndTime: api.timeshift_end_time,
      serialTitle: api.serial_title,
    });
  }
}
