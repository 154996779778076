<script>
import useVueFocusableHook from '@package/smarttv-navigation/src/useVueFocusableHook';
import { inject, onMounted } from '@vue/composition-api';

export default {
  props: {
    group: { default: '' },
    tag: { default: 'div' },
    activeClass: { default: '' },
    loop: { default: '' },
    focusKey: { type: String, required: false },
    autofocus: { type: Boolean, required: false },
    propParentFocusKey: { type: String, required: false },
    focusBoundaryDirections: { type: Array, required: false },
    isFocusBoundary: { type: Boolean, required: false, default: false },
    hasGlobalAccess: { type: Boolean, required: false, default: false },
    onClick: {
      type: Function,
    },
    onFocus: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
  },
  data: () => ({
    isActive: false,
  }),
  setup(props) {
    const updateLayoutOnFocus = inject('updateLayoutOnFocus', true);

    const { el, focused, focusSelf } = useVueFocusableHook({
      focusable: true,
      trackChildren: true,
      updateLayoutOnFocus,
      autoRestoreFocus: true,
      focusKey: props.focusKey,
      hasGlobalAccess: props.hasGlobalAccess,
      isFocusBoundary: props.isFocusBoundary,
      parentFocusKey: props.propParentFocusKey,
      focusBoundaryDirections: props.focusBoundaryDirections,
      preferredChildFocusKey: null,
      onEnterPress: () => {
        if (props.onClick) {
          props.onClick();
        }
      },
      onEnterRelease: () => {},
      onArrowPress: () => true,
      onFocus: () => {
        if (props.onFocus) {
          props.onFocus();
        }
      },
      onBlur: () => {
        if (props.onBlur) {
          props.onBlur();
        }
      },
    });

    onMounted(() => {
      if (props.autofocus) {
        focusSelf();
      }
    });

    return {
      el,
      focused,
    };
  },
  computed: {
    classNames() {
      return {
        [this.$style.navigatable]: true,
        [this.$style.active]: !this.activeClass && this.isActive,
        [this.activeClass]: this.activeClass && this.isActive,
      };
    },
    element() {
      return this.el.$el;
    },
  },
  watch: {
    focused: {
      handler(value) {
        this.setActiveCurrentItem(value);
      },
      immediate: true,
    },
  },
  methods: {
    setActiveCurrentItem(active) {
      if (this.isActive && !active) {
        this.$emit('inactive', this.element);
      }

      this.isActive = active;

      if (!active) {
        return;
      }

      this.$emit('active', this.element);
    },
  },
  render(h) {
    return h(this.tag, {
      class: this.classNames,
      ref: 'el',
      attrs: {
        'data-navigatable': this.group,
        'data-loop': this.loop,
        'data-navigatable-active': this.isActive,
        ...this.$attrs,
      },
      props: {
        isFocused: this.focused,
      },
      scopedSlots: Object.keys(this.$slots).reduce((slots, key) => {
        slots[key] = () => this.$slots[key];

        return slots;
      }, {}),
    });
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.navigatable {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    content: '';
  }

  &.active::after {
    border: solid adjustPx(7px) var(--color-bg-accent);
  }
}

.highlight {
  border: solid adjustPx(7px) var(--color-text-negative);

  &::after {
    border: solid adjustPx(7px) var(--color-text-negative);
  }
}
</style>
